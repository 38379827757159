<template>
  <div v-loading="modalLoading">
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row
        type="flex"
        :gutter="30"
      >
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col
          :lg="12"
          :md="12"
          :sm="12"
          :xs="12"
        >
          <div class="form-group">
            <el-form-item label="Payment Field">
              <el-select
                v-model="parent_key"
                no-data-text="No Fields available"
                filterable
                default-first-option
              >
                <el-option
                  v-for="(field, index) of payButtonFields"
                  :value="field.key"
                  :key="index"
                  :label="field.label"
                >
                  <span style="float: left">{{ field.label }}</span>
                  <!-- <span
                    style="float: right; color: #8492a6; font-size: 13px"
                    v-if="entity.is_template_variable"
                  >Template variable</span> -->
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :lg="12"
          :md="12"
          :sm="12"
          :xs="12"
        >
          <div class="form-group">
            <el-form-item label="Payment Variable">
              <el-select
                v-model="field.global_variable_pay_field"
                :no-data-text="
                  'No variables available'
                "
                filterable
                default-first-option
                @change="setFieldData"
              >
              <el-option
                  v-for="(payObjField, index) of paymentFields"
                  :value="payObjField.key"
                  :key="index"
                  :label="payObjField.label"
                >
                  <span style="float: left">{{ payObjField.label }}</span>
                  <!-- <span
                    style="float: right; color: #8492a6; font-size: 13px"
                    v-if="entity.is_template_variable"
                  >Template variable</span> -->
                </el-option>
              
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :lg="12">
          Is this field?
          <el-radio-group
            v-model="field.field_assignable"
            size="small"
          >
            <el-radio label="read_only">Read only</el-radio>
            <!-- <el-radio label="editable">Editable</el-radio>
            <el-radio label="update_parent">Update parent</el-radio> -->
          </el-radio-group>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";

export default {
  name: "templates-formComponents-payVariable",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder")
  },
  mixins: [entityRelationshipMixin],
  computed: {
    ...mapGetters("userTypes", ["getUserTypes"]),
    getValidUserTypes() {
      return (this.getUserTypes.data || []).filter(
        userType => userType.name != "ADMIN"
      );
    },
  },
  props: ["field", "fieldsData"],
  data() {
    return {
      modalLoading: false,
      parent_key: "",
      payButtonFields:[], 
      paymentFields : [
          { label: "Transaction Id", key: "txn_id", input_type:"STRING" },
          { label: "Gateway", key: "gateway", input_type:"STRING" },
          { label: "Name", key: "name", input_type:"STRING" },
          { label: "Email", key: "email", input_type:"STRING" },
          { label: "Phone Number", key: "phoneNumber", input_type:"PHONE_COUNTRY_CODE" },
          { label: "Payment Type", key: "paymentType" , input_type:"STRING"},
          { label: "Method", key: "method" , input_type:"STRING"},
          { label: "Currency", key: "currency", input_type:"STRING" },
          { label: "Status", key: "status" , input_type:"STRING"},
          { label: "Payment Session", key: "paymentSession", input_type:"STRING" },
          { label: "Description", key: "description", input_type:"STRING" },
          { label: "Payment Date", key: "paymentDate", input_type:"DATE" },
          { label: "Refund Date", key: "refundDate", input_type:"DATE" },
          { label: "Total Amount", key: "amount", input_type:"CURRENCY" },
          { label: "Paid Amount", key: "paidAmount", input_type:"CURRENCY" },
          { label: "Total Paid Amount", key: "totalPaidAmount", input_type:"CURRENCY" },
          { label: "Amount Refunded", key: "amountRefunded", input_type:"CURRENCY" },
        ],
    };
  },
  async mounted() {
    this.modalLoading = true;
    await this.addSelfTemplateFields();
    if (!this.field.field_assignable) {
      this.$set(this.field, "field_assignable", "read_only");
    }
    this.modalLoading = false;
    if(this.field.parent_key)
    {
      this.parent_key=this.field.parent_key;
    }

  },
  methods: {
    addSelfTemplateFields() {
      if (this.fieldsData && this.fieldsData.length) {
        // this.payButtonFields = this.lodash.filter(
        //     this.fieldsData,
        //     function (element) {
        //       return element.input_type === "PAY_BUTTON";
        //     }
        //   );
          this.payButtonFields = this.fieldsData.filter(element => element.input_type === "PAY_BUTTON")
          
      }
    },
    setFieldData(fieldId) {
      let selectedEntityField = {};
      selectedEntityField = this.paymentFields.find(
        field => field.key == fieldId
      );
      let selectedPaymentField = this.payButtonFields.find(
        field => field.key == this.parent_key
      )
      this.field.key=this.parent_key+'_'+fieldId;
      this.field.inputType = selectedEntityField.input_type;
      this.$set(this.field.validations, "currency",selectedPaymentField.validations.currency);
      this.$set(this.field.validations, "locale",selectedPaymentField.validations.locale);
      this.$set(this.field.validations,"updateRules",selectedPaymentField.validations);
      
      // //storing template id & field key separately
      // if (fieldId && fieldId.includes("#")) {
      //   [
      //     this.field.global_variable_entity_field_template_id,
      //     this.field.global_variable_entity_select_type_field_key
      //   ] = fieldId.split("#");
      // }
      // if (selectedEntityField == undefined) {
      //   this.actionFieldData.forEach(repeatableTemps => {
      //     repeatableTemps.sections[0].fields.forEach(field => {
      //       if (field._id == fieldId) {
      //         selectedEntityField = field;
      //       }
      //     });
      //   });
      // }
      // this.selectedEntityFieldData = selectedEntityField;
      // if (selectedEntityField) {
      //   if (
      //     selectedEntityField.global_variable_id &&
      //     selectedEntityField.is_global_variable
      //   ) {
      //     this.field.global_variable_id =
      //       selectedEntityField.global_variable_id;
      //     this.field.is_global_variable = true;
      //   }
      //   if (selectedEntityField.inputType) {
      //     this.field.inputType = selectedEntityField.inputType;
      //   }

      //   if (
      //     selectedEntityField.entity_id &&
      //     selectedEntityField.inputType == "ENTITY"
      //   ) {
      //     this.field.entity_id = selectedEntityField.entity_id;
      //   }
      // }
      // this.field.selected_enity_field_data = this.selectedEntityFieldData;
      // if (selectedEntityField && selectedEntityField.is_relational) {
      //   this.field.entity_id = selectedEntityField.relation_entity_id;
      //   this.field.relationship_entity_id = selectedEntityField.self_entity_id;
      // }
      // if (this.parent_key) {
      //   this.field.global_variable_entity_parent = this.parent_key;
      // }
    },
  }
};
</script>

<style lang="scss"></style>
